import React, { FC, CSSProperties } from 'react'
import { BsFillXCircleFill } from "react-icons/bs"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?:()=>void
}

export const CloseCircleIcon: FC<Props> = ( { onClick, className, style } ) =>
    <BsFillXCircleFill className={ className } style={ style } onClick={ () => onClick && onClick()}/>
