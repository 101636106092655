import React, { useState, useEffect, CSSProperties, FC } from "react"
import Styles from "../../styles/InputForm.module.scss";
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx"
import { CloseCircleIcon } from "../../icons/CloseCircleIcon";
import { CheckCircleIcon } from "../../icons/CheckCircleIcon";

// const NumberReg = /(?:.+.)?[0-9](?:.+.)?/
const AlphabetReg = /(?:.+.)?[a-z](?:.+.)?/

type InputFormProps = {
    label: string,
    name: string,
    value: string,
    placeholder: string,
    type: string,
    errorMessage?: string | string[],
    style?: CSSProperties,
    onChange: ( name: string, value: string ) => void,
    labelStyle?: CSSProperties,
    onKeyPress?: ( e: React.KeyboardEvent<HTMLInputElement> ) => void,
    inputFormStyle?: CSSProperties,
    displayPasswordCondition?: boolean
    autoFocus?:boolean
}

const InputForm: FC<InputFormProps> = ( {
    label,
    name,
    value,
    placeholder,
    type,
    errorMessage,
    style,
    onChange,
    labelStyle = {},
    onKeyPress = () => { },
    inputFormStyle,
    displayPasswordCondition,
    autoFocus
} ) => {

    const [ passwordShow, setPasswordShow ] = useState( false )
    const [ passwordContainsAlphabet, setPasswordContainsAlphabet ] = useState( false )
    const [ passwordHasMoreThan8Char, setPasswordHasMoreThan8Char ] = useState( false )


    useEffect( () => {
        if ( !displayPasswordCondition ) return
        setPasswordHasMoreThan8Char( !!value && 8 <= value.length )
        setPasswordContainsAlphabet( ( AlphabetReg.test( value ) ) )
    }, [ value ] )

    return (
        <div className={ Styles.container } style={ style }>
            <label style={ labelStyle }>{ label }</label>
            <div style={ { position: "relative" } }>
                <input
                    autoFocus={ autoFocus}
                    type={ passwordShow ? "text" : type }
                    value={ value }
                    autoCapitalize="none"
                    style={ { ...inputFormStyle } }
                    placeholder={ placeholder }
                    name={ name }
                    className={ clsx( ( placeholder && 15 < placeholder.length ) && Styles.long_placeholder ) }
                    onKeyPress={ onKeyPress }
                    onChange={ ( e ) => onChange( name, e.target.value ) } />
                { type === "password" &&
                    <CloseCircleIcon
                        onClick={ () => onChange( name, "" ) }
                        className={ Styles.password_clear_button } /> }
            </div>
            { errorMessage &&
                <p className={ Styles.error }>{ errorMessage }</p> }
            { displayPasswordCondition &&
                <>
                    <div className={ Styles.password_condition_container }>
                        <CheckCircleIcon
                            className={ passwordHasMoreThan8Char ? Styles.OK_icon : Styles.NG_icon } />
                        <p className={ clsx( Styles.password_condition, passwordHasMoreThan8Char && Styles.OK_condition ) }>8文字以上</p>
                    </div>
                    <div className={ Styles.password_condition_container }>
                        <CheckCircleIcon
                            className={ passwordContainsAlphabet ? Styles.OK_icon : Styles.NG_icon } />
                        <p className={ clsx( Styles.password_condition, passwordContainsAlphabet && Styles.OK_condition ) }>アルファベットを含む</p>
                    </div>
                </> }
            { type === "password" &&
                <p className={ Styles.password_show_container }>
                    { passwordShow ?
                        <FontAwesomeIcon
                            tabIndex={ 0 }
                            className={ Styles.password_show_icon }
                            style={ { color: "#888" } }
                            icon={ faCheckSquare }
                            onKeyPress={ ( e ) => {
                                e.key === "Enter" && setPasswordShow( !passwordShow )
                            } }
                            onClick={ () => setPasswordShow( !passwordShow ) } /> :
                        <FontAwesomeIcon
                            tabIndex={ 0 }
                            className={ Styles.password_show_icon }
                            icon={ faSquare }
                            onKeyPress={ ( e ) => {
                                e.key === "Enter" && setPasswordShow( !passwordShow )
                            } }
                            onClick={ () => setPasswordShow( !passwordShow ) } />
                    }
                    <span style={ labelStyle }>パスワードを表示する</span>
                </p> }
        </div>
    )
}

export default InputForm
