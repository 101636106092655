import { useEffect } from 'react'
import { navigate } from "gatsby"
import { useSelector } from 'react-redux'
import { AppState } from '../state/app'


export const useRedirectFromWelcome = () => {
    const { hasCompletedRegistration } = useSelector( ( state: AppState ) => state.app )

    useEffect( () => {
        hasCompletedRegistration && navigate( "/home" )
    }, [ hasCompletedRegistration ] )
}
