import React, { FC, useContext, useEffect } from "react"
import Styles from "../../styles/welcome.module.scss";
import { navigate } from "gatsby";
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import GeneralButton from "../../components/atoms/GeneralButton";
import InputForm from "../../components/atoms/InputForm";
import { WelcomeContext } from "../../context/WelcomeContext";
import { ToastContext } from "../../context/ToastContext";
import { useRedirectFromWelcome } from "../../hooks/useRedirectFromWelcome";


const headerOption = {
    headerTitle: "ようこそ",
    // leftIcon:"backButton",
}

type Props ={
    location:any
}

const WelcomePage:FC<Props> = ( { }) => {

    useRedirectFromWelcome()
    useEffect( () => {
        document.documentElement.scrollTop = 0
    }, [] )

    const { name, setName } = useContext( WelcomeContext )
    const { addToast } = useContext( ToastContext )

    return (
        <Layout headerOption={ headerOption } footerHide={ true }>
            <>
                <SEO title="Home" />
                <div className={ Styles.container }>
                    <h1 className={Styles.title}>{ "1. 基本情報入力" }</h1>
                    <p className={ Styles.description }>後で変更できます</p>
                    <div className={ Styles.content_wrapper}>
                        <div className={Styles.content}>
                            <InputForm
                                name="name"
                                label="みんなに見えるお名前"
                                value={ name }
                                type="text"
                                labelStyle={ { fontWeight: "bold" } }
                                placeholder="クラブ太郎"
                                onChange={ ( name, value ) => setName( value ) }
                            />
                        </div>
                    </div>
                    <GeneralButton
                        title="次へ"
                        style={ { minWidth: 200, } }
                        isDisabled={ !name }
                        disabledOnClick={ () => addToast( {
                            type: "warn",
                            position: "center",
                            text: "お名前を入力してください"
                        } ) }
                        onClick={ () => navigate( "/welcome/survey" ) } />
                </div>
            </>
        </Layout>
    )
}

export default WelcomePage
